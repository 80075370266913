
import Form from "@/components/Form.vue";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    Form
  }
})
export default class BaseForm extends Vue {}
