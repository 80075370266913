export interface IQuotationTimeLimit {
  id: number;
  timeLimit: string;
  position: number;
}

export class QuotationTimeLimitDto implements IQuotationTimeLimit {
  id: number = 0;
  timeLimit: string = "";
  position = 0;
}

export default class QuotationTimeLimit extends QuotationTimeLimitDto {
  constructor(dto: QuotationTimeLimitDto) {
    super();
    Object.assign(this, dto);
  }
}
