
import { Vue, Component, Prop } from "vue-property-decorator";
import { mixin as clickaway } from "vue-clickaway";

@Component({ mixins: [clickaway] })
export default class InputField extends Vue {
  clickedOnce: boolean = false;
  isValid: boolean = true;

  @Prop({ default: "" })
  id!: string;

  @Prop({ default: "" })
  label!: string;

  @Prop({ default: "" })
  value!: string;

  @Prop({ default: "" })
  favicon!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: "text" })
  type!: string;

  @Prop({ default: "" })
  placeholder!: string;

  @Prop({ default: 0 })
  maxLength!: number;

  @Prop({ default: 0 })
  minLength!: number;

  @Prop({ default: "" })
  validationMessage!: string;

  @Prop({ default: "" })
  pattern!: string;

  @Prop({ default: false })
  textarea!: boolean;

  registerClick() {
    this.clickedOnce = true;
  }

  get calculateRows(): number {
    if (this.value == "") {
      return 1;
    }
    return Math.ceil(this.value.length / 60);
  }

  validateInput() {
    if (!this.clickedOnce) {
      return;
    }
    this.isValid = true;
    if (this.required || this.minLength > 0 || this.maxLength > 0) {
      if (this.value.length == 0) {
        this.isValid = false;
      } else if (this.value.length < this.minLength && this.minLength != 0) {
        this.isValid = false;
      } else if (this.value.length > this.maxLength && this.maxLength != 0) {
        this.isValid = false;
      } else if (this.pattern.length > 0) {
        let regex = new RegExp(this.pattern);
        this.isValid = regex.test(this.value);
      }
    }
  }

  updateValue(value: any) {
    this.$emit("input", value);
  }

  enter() {
    this.$emit("enter");
  }
}
