
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class FileUpload extends Vue {
  @Watch("files", { immediate: true, deep: true })
  onFileUpload(files: Array<File>) {
    this.$emit("change", files);
  }

  files: Array<File> = [];

  uploadDisabled(): boolean {
    return this.files.length === 0;
  }

  addFileSelect(event: any) {
    if (event.target == null) {
      return;
    }

    const selectedFiles = event.target.files;
    if (!selectedFiles) {
      return;
    }
    [...selectedFiles].forEach((f) => {
      this.files.push(f);
    });
  }

  addFileDrop(event: DragEvent): void {
    if (event.dataTransfer == null) {
      return;
    }

    const droppedFiles = event.dataTransfer.files;
    if (!droppedFiles) {
      return;
    }

    [...droppedFiles].forEach((f) => {
      this.files.push(f);
    });
  }

  removeFile(file: File): void {
    this.files = this.files.filter((f) => {
      return f != file;
    });
  }
}
