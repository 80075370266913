import { HttpClient } from "./HttpClient";
import QuotationCategory, {
  QuotationCategoryDto
} from "../models/QuotationCategory";
import Question, { QuestionDto } from "../models/Question";
import QuotationTimeLimit, {
  QuotationTimeLimitDto
} from "@/models/QuotationTimeLimit";
import { QuotationRequestDto } from "@/models/QuotationRequest";
import { AxiosResponse } from "axios";

export class QuotationService {
  static async GetChildCategories(
    parentCategory: string
  ): Promise<Array<QuotationCategory>> {
    const response = await HttpClient.get(
      `/quotation/child-categories?parentCategoryName=${parentCategory}`
    );
    if (response.status == 200) {
      return response.data.map(
        (quotationRequest: QuotationCategoryDto) =>
          new QuotationCategory(quotationRequest)
      );
    }
    return [];
  }

  static async GetCategories(): Promise<Array<QuotationCategory>> {
    const response = await HttpClient.get("/quotation/categories");
    if (response.status == 200) {
      return response.data.map(
        (quotationRequest: QuotationCategoryDto) =>
          new QuotationCategory(quotationRequest)
      );
    }
    return [];
  }

  static async GetQuestions(childCategoryId: number): Promise<Array<Question>> {
    const response = await HttpClient.get(
      `/quotation/questions?childCategoryId=${childCategoryId}`
    );
    if (response.status == 200) {
      return response.data.map(
        (question: QuestionDto) => new Question(question)
      );
    }
    return [];
  }

  static async GetQuotationTimeLimits(): Promise<Array<QuotationTimeLimit>> {
    const response = await HttpClient.get("/quotation/time-limits");
    if (response.status == 200) {
      return response.data.map(
        (timeLimit: QuotationTimeLimitDto) => new QuotationTimeLimit(timeLimit)
      );
    }
    return [];
  }

  static async SubmitQuotationRequest(
    QuotationRequest: QuotationRequestDto
  ): Promise<AxiosResponse> {
    return HttpClient.post("/quotation/submit", QuotationRequest);
  }

  static async DeleteQuotationRequest(code: string): Promise<AxiosResponse> {
    return HttpClient.post(`/quotation/delete-request/${code}`);
  }
}
