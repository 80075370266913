export interface IQuestion {
  id: number;
  text: string;
  position: number;
  mandatory: boolean;
  active: boolean;
}

export class QuestionDto implements IQuestion {
  id: number = 0;
  text: string = "";
  position: number = 0;
  mandatory: boolean = false;
  active: boolean = true;
}

export default class Question extends QuestionDto {
  answer: string = "";
  constructor(dto: QuestionDto) {
    super();
    Object.assign(this, dto);
  }

  get getQuestionsAndAnswer(): string {
    return `${this.text} - ${this.answer}`;
  }
}
