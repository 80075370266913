
import FileUpload from "@/components/FileUpload.vue";
import InputField from "@/components/InputField.vue";
import PageLoader, { PageLoaderState } from "@/components/PageLoader.vue";

import Multiselect from "vue-multiselect";
import { QuotationService } from "@/api/QuotationServiceApi";
import { Vue, Component, Prop } from "vue-property-decorator";
import QuotationCategory, {
  QuotationCategoryDto
} from "@/models/QuotationCategory";
import ContactInfo, { ContactInfoDto } from "@/models/ContactInfo";
import QuotationTimeLimit from "@/models/QuotationTimeLimit";
import QuotationRequest, {
  QuotationRequestDto
} from "@/models/QuotationRequest";
import { IFile } from "@/models/File";
import { IErrorDetails } from "@/models/ErrorDetails";
import { TranslateResult } from "vue-i18n";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import { GetLocale, TranslateCategory } from "@/utility/LanguageHelper";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    FileUpload,
    Multiselect,
    InputField,
    PageLoader,
    TermsAndConditions
  },
  metaInfo(): MetaInfo {
    const lang = GetLocale();
    let parentCategory = this.$route.params.parentCategory as string;
    let childCategory = this.$route.params.childCategory as string;

    let lookup = parentCategory;
    if (childCategory != undefined && childCategory.length > 0) {
      lookup = lookup + "/" + childCategory;
    }

    if (this.$te(`META_DATA.${lookup}`, lang)) {
      return {
        title: this.$t(`META_DATA.${lookup}.TITLE`).toString(),
        meta: [
          {
            name: "description",
            content: this.$t(`META_DATA.${lookup}.CONTENT`).toString()
          }
        ],
        htmlAttrs: {
          lang: lang
        }
      };
    } else {
      return {
        title: "Jämför priser på tjänsteföretag - Gratis - Dealfinder",
        meta: [
          {
            name: "description",
            content:
              "Jämför priser på ditt jobb - alltid en kostnadsfritjänst utan några villkor. beskriv ditt jobb och få hjälp av kontrollerade företag!"
          }
        ]
      };
    }
  }
})
export default class Form extends Vue {
  @Prop({ default: "" })
  category!: string;

  @Prop({ default: "" })
  childCategory!: string;

  acceptedTermsAndConditions: boolean = false;
  errorDetails: IErrorDetails = {
    errors: []
  };

  value: QuotationCategory | null = null;

  pageLoaderState = PageLoaderState.Loading;

  files: Array<IFile> = [];

  otherInformation: string = "";
  selectedCategory: QuotationCategory = new QuotationCategory(
    new QuotationCategoryDto()
  );
  selectedTimeLimit: QuotationTimeLimit = { id: 0, timeLimit: "", position: 0 };
  contactInfo: ContactInfo = new ContactInfo(new ContactInfoDto());

  timeLimits: Array<QuotationTimeLimit> = [];
  options: Array<QuotationCategory> = [];
  url: string = "";
  skipRouteUpdate: boolean = false;

  get optionsSortedByAlphabeticalOrder() {
    return this.options.sort(function(a, b) {
      const n1 = TranslateCategory(a).toLowerCase();
      const n2 = TranslateCategory(b).toLowerCase();
      if (n1 < n2) {
        return -1;
      }
      if (n1 > n2) {
        return 1;
      }
      return 0;
    });
  }

  async created() {
    await this.loadTimeLimits();
    await this.loadChildCategories();
    if (this.options.length == 0) {
      await this.$router.push("/");
      return;
    }

    this.url = this.$route.fullPath;
    if (this.childCategory) {
      await this.preselectCategory();
    }

    this.pageLoaderState = PageLoaderState.Completed;
  }

  async loadTimeLimits() {
    if (this.timeLimits.length == 0) {
      this.timeLimits = await QuotationService.GetQuotationTimeLimits();
      this.sortTimeLimits();
      this.selectedTimeLimit = this.timeLimits[0];
    }
  }

  async loadChildCategories() {
    await QuotationService.GetChildCategories(
      this.tryResolveQueryParameter(this.category)
    )
      .then(response => {
        this.options = response;
      })
      .catch(() => {});
  }

  async preselectCategory() {
    let formattedParam = this.tryResolveQueryParameter(this.childCategory);
    let selectedChildCategory:
      | QuotationCategory
      | undefined = this.options.find(c => c.name == formattedParam);

    if (selectedChildCategory == undefined) {
      let originalPath = this.$router.currentRoute.fullPath.replaceAll(
        "/" + this.childCategory,
        ""
      );

      await this.$router.push(originalPath);
    } else {
      this.skipRouteUpdate = true;
      await this.updateSelected(selectedChildCategory);
      this.value = selectedChildCategory;
    }
  }

  customLabel(option: QuotationCategory): TranslateResult {
    return this.$t("QUOTATION_REQUEST_CHILD_CATEGORY." + `${option.name}`);
  }

  async sendForm() {
    this.pageLoaderState = PageLoaderState.Loading;
    this.$gtm.trackEvent({
      category: "QuotationRequest",
      action: "Submit",
      label: this.selectedCategory.name,
      value: 1
    });

    const quotationRequest: QuotationRequest = new QuotationRequest(
      this.contactInfo,
      this.selectedTimeLimit,
      this.otherInformation,
      this.selectedCategory,
      this.files,
      this.$i18n.locale,
      this.acceptedTermsAndConditions
    );

    const quotationRequestDto: QuotationRequestDto = quotationRequest.mapToDto();
    await QuotationService.SubmitQuotationRequest(quotationRequestDto)
      .then(async () => {
        this.pageLoaderState = PageLoaderState.Completed;
        this.$swal
          .fire({
            titleText: this.$t(
              "MODAL.QUOTATION_REQUEST_SUBMIT_SUCCESS.TITLE"
            ).toString(),
            text: this.$t(
              "MODAL.QUOTATION_REQUEST_SUBMIT_SUCCESS.SUB_TITLE"
            ).toString(),
            confirmButtonText: "Ok",
            icon: "success"
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push("/");
              window.scrollTo(0, 0);
            }
          });
      })
      .catch(error => {
        this.errorDetails = error.response.data as IErrorDetails;
        this.pageLoaderState = PageLoaderState.Completed;
        this.$swal.fire({
          titleText: this.$t(
            "MODAL.QUOTATION_REQUEST_SUBMIT_ERROR.TITLE"
          ).toString(),
          text: this.$t(
            "MODAL.QUOTATION_REQUEST_SUBMIT_ERROR.SUB_TITLE"
          ).toString(),
          confirmButtonText: "Ok",
          icon: "error"
        });
      });
  }

  sortTimeLimits(): void {
    this.timeLimits.sort((q1, q2) => {
      if (q1.position > q2.position) {
        return 1;
      }

      if (q1.position < q2.position) {
        return -1;
      }

      return 0;
    });
  }

  getUrlSlashIndexes(url: string): Array<number> {
    let slashes: Array<number> = [];
    for (let i = 0; i < url.length; i++) {
      if (url[i] === "/") {
        slashes.push(i);
      }
    }

    return slashes;
  }

  updateRoute(): void {
    let urlFormatted = this.url;
    let slashes = this.getUrlSlashIndexes(urlFormatted);

    const count = slashes.length;
    if (count == 4) {
      urlFormatted = urlFormatted.slice(0, slashes[count - 2]);
    } else if (count == 3) {
      urlFormatted = urlFormatted.slice(0, slashes[count - 1]);
    }

    if (urlFormatted.charAt(urlFormatted.length - 1) != "/") {
      urlFormatted = urlFormatted + "/";
    }

    this.$router.push(
      urlFormatted +
        this.$t(
          `ROUTES.QUOTATION_REQUEST_CHILD_CATEGORY.${this.selectedCategory.name}`
        )
    );
  }

  async updateSelected(category: QuotationCategory): Promise<void> {
    this.selectedCategory = category;
    await category.loadQuestions();
    if (!this.skipRouteUpdate) {
      this.updateRoute();
    }
    this.skipRouteUpdate = false;
  }

  tryResolveQueryParameter(query: string): string {
    const lang = GetLocale();
    if (
      this.$te(
        `TRANSLATED_ROUTES.PARENT.QUOTATION_REQUEST_CATEGORY.${query}`,
        lang
      )
    ) {
      return this.$t(
        `TRANSLATED_ROUTES.PARENT.QUOTATION_REQUEST_CATEGORY.${query}`
      ) as string;
    } else if (
      this.$te(
        `TRANSLATED_ROUTES.CHILD.QUOTATION_REQUEST_CHILD_CATEGORY.${query}`,
        lang
      )
    ) {
      return this.$t(
        `TRANSLATED_ROUTES.CHILD.QUOTATION_REQUEST_CHILD_CATEGORY.${query}`
      ) as string;
    }
    return "";
  }

  copyFiles(files: Array<File>) {
    this.files = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file: File = files[i];
      reader.onload = event => {
        if (event.target == null) {
          return;
        }

        this.files.push({
          id: 0,
          fileName: file.name,
          type: file.type,
          base64: event.target.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  setTimeLimit(event: any): void {
    for (let i = 0; i < this.timeLimits.length; i++) {
      const timeLimit = this.timeLimits[i];
      if (timeLimit.id == event.target.value) {
        this.selectedTimeLimit = timeLimit;
        break;
      }
    }
  }

  updateTermsAndConditionsAcceptance() {
    this.acceptedTermsAndConditions = !this.acceptedTermsAndConditions;
  }

  get pageHeader() {
    const lang = GetLocale();
    if (this.$te(`META_DATA.${this.path}`, lang)) {
      return this.$t(`META_DATA.${this.path}.KEYWORD`).toString();
    }
    return this.$t("QUOTATION_REQUEST_FORM.TITLE").toString();
  }

  get keywordTextExists(): boolean {
    const lang = GetLocale();
    return this.$te(`META_DATA.${this.path}`, lang);
  }

  get keywordText() {
    return this.$t(`META_DATA.${this.path}.KEYWORD_TEXT`).toString();
  }

  get path(): string {
    let parentCategory = this.$route.params.parentCategory as string;
    let childCategory = this.$route.params.childCategory as string;

    let path = parentCategory;
    if (childCategory != undefined && childCategory.length > 0) {
      path = path + "/" + childCategory;
    }

    return path;
  }
}
