export interface IContactInfo {
  name: string;
  email: string;
  mobilePhone: string;
  zipCode: string;
}

export class ContactInfoDto implements IContactInfo {
  name: string = "";
  email: string = "";
  mobilePhone: string = "";
  zipCode: string = "";
}

export default class ContactInfo extends ContactInfoDto {
  constructor(dto: ContactInfoDto) {
    super();
    Object.assign(this, dto);
  }

  validate(): Array<string> {
    const errors: Array<string> = [];
    if (this.name.length == 0) {
      errors.push("Fyll i ditt förnamn");
    }

    if (this.email.length == 0) {
      errors.push("Fyll i din Epost");
    }

    if (this.mobilePhone.length == 0) {
      errors.push("Fyll i ditt telefonnummer");
    }

    if (this.zipCode.length < 5) {
      errors.push("Fyll i din postaddress, 5 siffror");
    }
    return errors;
  }
}
