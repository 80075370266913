import { render, staticRenderFns } from "./FileUpload.vue?vue&type=template&id=6c394770&scoped=true"
import script from "./FileUpload.vue?vue&type=script&lang=ts"
export * from "./FileUpload.vue?vue&type=script&lang=ts"
import style0 from "./FileUpload.vue?vue&type=style&index=0&id=6c394770&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c394770",
  null
  
)

export default component.exports