import Question from "./Question";
import { QuotationService } from "@/api/QuotationServiceApi";

export interface IQuotationCategory {
  id: number;
  name: string;
}

export class QuotationCategoryDto implements IQuotationCategory {
  id: number = 0;
  name: string = "";
}

export default class QuotationCategory extends QuotationCategoryDto {
  questions: Array<Question> = [];
  constructor(dto: QuotationCategoryDto) {
    super();
    Object.assign(this, dto);
  }

  get getQuestions(): Array<Question> {
    return this.questions;
  }

  async loadQuestions() {
    if (this.questions.length > 0) {
      return;
    }
    this.questions = await QuotationService.GetQuestions(this.id);
    this.sortQuestions();
  }

  sortQuestions(): void {
    this.questions.sort((q1, q2) => {
      if (q1.position > q2.position) {
        return 1;
      }

      if (q1.position < q2.position) {
        return -1;
      }

      return 0;
    });
  }
}
