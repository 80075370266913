import ContactInfo from "./ContactInfo";
import QuotationTimeLimit from "./QuotationTimeLimit";
import QuotationCategory from "./QuotationCategory";
import { IFile } from "./File";

class QuestionAndAnswerDto {
  questionId: number = 0;
  answer: string = "";
}

export class QuotationRequestDto {
  name: string = "";
  lastName: string = "";
  email: string = "";
  mobilePhone: string = "";
  zipCode: string = "";
  timeLimitId: number = 0;
  otherInformation: string = "";
  language: string = "";
  acceptedTermsAndConditions: boolean = false;
  quotationChildCategoryId: number = 0;
  questionsAndAnswers: Array<QuestionAndAnswerDto> = [];
  files: Array<IFile> = [];
}

export default class QuotationRequest {
  acceptedTermsAndConditions: boolean;
  language: string;
  contactInfo: ContactInfo;
  quotationTimeLimit: QuotationTimeLimit;
  otherInformation: string;
  category: QuotationCategory;
  files: Array<IFile>;
  constructor(
    contactInfo: ContactInfo,
    quotationTimeLimit: QuotationTimeLimit,
    otherInformation: string,
    category: QuotationCategory,
    files: Array<IFile>,
    language: string,
    acceptedTermsAndConditions: boolean
  ) {
    this.contactInfo = contactInfo;
    this.quotationTimeLimit = quotationTimeLimit;
    this.otherInformation = otherInformation;
    this.category = category;
    this.files = files;
    this.language = language;
    this.acceptedTermsAndConditions = acceptedTermsAndConditions;
  }

  mapToDto(): QuotationRequestDto {
    const quotationRequest: QuotationRequestDto = new QuotationRequestDto();
    quotationRequest.name = this.contactInfo.name;
    quotationRequest.email = this.contactInfo.email;
    quotationRequest.mobilePhone = this.contactInfo.mobilePhone;
    quotationRequest.zipCode = this.contactInfo.zipCode;
    quotationRequest.otherInformation = this.otherInformation;
    quotationRequest.timeLimitId = this.quotationTimeLimit.id;
    quotationRequest.quotationChildCategoryId = this.category.id;
    quotationRequest.language = this.language;
    quotationRequest.acceptedTermsAndConditions = this.acceptedTermsAndConditions;
    quotationRequest.files = [...this.files];
    this.category.questions.forEach(question => {
      const questionAndAnswerDto: QuestionAndAnswerDto = new QuestionAndAnswerDto();
      questionAndAnswerDto.questionId = question.id;
      questionAndAnswerDto.answer = question.answer;
      quotationRequest.questionsAndAnswers.push(questionAndAnswerDto);
    });

    return quotationRequest;
  }
}
